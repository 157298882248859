import React from "react"
import { Container, Row, Col } from "reactstrap"
import { useStaticQuery, graphql } from "gatsby"

import style from "./Footer.module.css"

import linkLogo from "../../images/livro-reclamacoes-120x50.png"

const Footer = () => {
  const data = useStaticQuery(graphql`
    query SiteFooterQuery {
      site {
        siteMetadata {
          links {
            href
            text
          }
        }
      }
    }
  `)

  const links = data.site.siteMetadata.links

  return (
    <footer>
      <Container className={style.container} fluid={true}>
        <Row>
          <Col
            xl="6"
            className={style.alignedCenterXL}
            style={{ display: "flex", alignItems: "center" }}
          >
            <nav className={style.floatLeft}>
              <ul>
                {links.map((link, index) => (
                  <li key={index}>
                    <a href={link.href}>{link.text}</a>
                  </li>
                ))}
              </ul>
            </nav>
          </Col>
          <Col xl="6">
            <div
              className={`${style.copyright} ${style.floatRight}`}
              style={{ display: "flex", alignItems: "center" }}
            >
              <div
                className={style.alignedCenterXL}
                style={{ paddingRight: "1rem" }}
              >
                <a
                  href="https://www.livroreclamacoes.pt"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img src={linkLogo} alt="Livro de Reclamações" />
                </a>
              </div>
              <div className={style.alignedCenterXL}>
                {" "}
                Copyright @ 2020{" "}
                <a
                  href="https://github.com/gualterandre"
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  Gualter Augusto
                </a>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </footer>
  )
}
export default Footer
